.login-body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;

  .bg-container {
    width: 50%;
    height: 100%;

    img {
      width: 78rem;
      height: 100%;
    }
  }

  .form-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      width: 55%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 7rem;
      margin-left: 12rem;

      .content-logo {
        width: 100%;
        .container-logo {
          width: 27%;
          margin-bottom: 4rem;
        }

        h1 {
          color: #fff;
          font-size: clamp(1.6rem, 3vw, 4rem);
          margin-bottom: 4.6rem;
        }
      }

      form.card {
        width: 100%;
        display: flex;
        flex-direction: column;

        .content-form {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          label {
            color: #bfa767;
            font-size: 1.5rem;
          }

          .container-input {
            width: 100%;

            .content-input {
              width: 100%;
              input {
                width: 85%;
                border-radius: 0.8rem;
                font-size: 1.6rem;
                height: 3rem;
                padding-inline-end: 3rem;
                outline: none;
                padding-left: 0.5rem;
                margin-top: 0.5rem;

                &::placeholder {
                  padding-left: 0.5rem;
                  font-size: 1.5rem;
                }
              }

              .MuiSvgIcon-root {
                color: #d60747;
                height: 32px !important;
                position: relative !important;
                right: 28px !important;
                vertical-align: bottom !important;
              }
            }

            p {
              color: #d60747;
              font-size: 1.3rem;
            }
          }

          .forgetPassword {
            text-decoration: none;
            color: #bfa767;
            font-size: 1.5rem;
            margin: 0.8rem 3.1rem 0.8rem 0;
          }

          button {
            font-style: normal;
            font-weight: 400;
            font-weight: 500;
            font-size: 1.8rem;
            text-align: center;
            border-color: transparent;
            color: #fff;
            background-color: #d60747;
            // padding-block: 1.1rem;
            padding-inline: 6.4rem;
            border-radius: 1.4rem;
            margin-right: 2.6rem;
            height: 4rem;
            cursor: pointer;

            &:hover {
              background-color: #d6074980;
            }
          }
        }
      }
    }
  }
}
